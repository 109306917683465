<template>
  <div>
    <div class="mb-20">
      <h2 class="text-2xl font-bold mr-3">Affiliate Leads</h2>
    </div>

    <section class="border border-blue-200 duration-300">
      <div>
        <datatable
          :url="leads.url"
          :ajax="true"
          :ajaxPagination="true"
          :actions="leads.actions"
          :columns="leads.columns"
          :filters="leads.filters"
          :query="leads.query"
          :fillable="true"
          :dropdown="false"
          sort="created_at"
          order="desc"
          ref="table"
        >
          <template #header>
            <div class="pt-4">
              <search-form
                placeholder="Search lead names, emails, phone numbers etc."
                class="mb-5"
                v-model="leads.query"
                @submit="reloadTable"
              />
              <div class="flex items-start flex-wrap xl:flex-no-wrap">
                <div class="flex flex-wrap items-center w-full">
                  <span class="processor-filter-title">Filters:</span>
                  <template v-for="(filter, index) in leads.filters">
                    <span
                      v-html="filter.title"
                      :key="index"
                      class="processor-filter-item"
                      :class="{ active: currentFilter === filter }"
                      @click.prevent="toggleFilter(filter)"
                    />
                  </template>
                </div>
              </div>
            </div>
          </template>
        </datatable>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: this.$options.basicForm([]),
      currentFilter: null,
      leads: {
        url: `${this.$baseurl}/admin/affiliates/leads`,
        query: '',
        columns: [
          { name: 'first_name', th: 'First Name' },
          { name: 'last_name', th: 'Last Name' },
          { name: 'email', th: 'Email' },
          { name: 'phone', th: 'Phone' },
          {
            name: 'product',
            th: 'Product',
          },
          {
            name: 'created_at',
            th: 'Date Created',
            render: (lead) => {
              return this.$moment(lead?.created_at).format('YYYY-MM-DD');
            },
          },
          {
            name: 'status',
            th: 'Status',
            render: ({ status }) => this.statusBadge(status),
          },
        ],
        actions: [
          {
            text: 'Accepted',
            class: 'btn btn-green btn-sm',
            action: this.markAsAccepted,
            show: ({ status }) => status === 'pending',
            disabled: () => this.form.loading,
          },
          {
            text: 'Rejected',
            class: 'btn btn-red btn-sm',
            action: this.markAsRejected,
            show: ({ status }) => status === 'pending',
            disabled: () => this.form.loading,
          },
        ],
        filters: [],
      },
    };
  },
  computed: {},
  methods: {
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    async markAsAccepted({ id }) {
      return this.updateStatus(id, 'accepted');
    },
    async markAsRejected({ id }) {
      return this.updateStatus(id, 'rejected');
    },
    async updateStatus(leadId, status) {
      this.form.loading = true;
      await this.sendRequest('admin.affiliate.updateLead', leadId, {
        data: { status },
        success: () => {
          this.reloadTable();
          this.$success({
            title: 'Operation Successful',
            body: 'Affiliate lead updated.',
            button: 'Okay',
          });
        },
        error: (error) => {
          this.$error({ title: error?.response?.data?.message });
        },
      }).finally(() => (this.form.loading = false));
    },
  },
};
</script>
