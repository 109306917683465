<template>
  <div>
    <div class="flex flex-col text-14 mb-5 mt-2 font-bold">
      <h2 class="text-2xl font-bold">Referrers</h2>
    </div>

    <modal className="w-full md:w-2/5 xl:w-2/5 p-10" ref="refereeActionModal">
      <div>
        <div class="mb-10">
          <div class="text-xl items-center font-bold mb-10">Action</div>

          <div v-if="referees.selected">
            <div class="flex flex-wrap gap-2 items-center">
              <router-link
                :to="{
                  name: 'staff-view',
                  params: { staffId: referees.selected.id },
                }"
                target="_blank"
                class="btn btn-blue"
              >
                <ion-icon name="body-outline" class="text-xl"></ion-icon>
                <span class="ml-2">View Profile</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <div class="border border-solid border-blue-200">
      <datatable
        :data="referees.data"
        :columns="referees.columns"
        :filters="referees.filters"
        :query="referees.query"
        :loading="referees.loading"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/personal/users/referrals-list`"
        :search-field="searchField"
        :on-click="click"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="referees.query"
            class="mb-5"
            placeholder="Search for user's name, ID, phone number"
            @submit="reloadData"
          />

          <div class="flex items-center mb-5">
            <template v-for="(filter, filterIndex) in referees.filters">
              <div
                :key="filterIndex"
                class="processor-filter-item"
                :class="{ active: currentFilter === filter }"
                @click.prevent="setFilter(filter)"
              >
                {{ filter.title }}
              </div>
            </template>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
    searchField: {
      type: [HTMLInputElement],
      default: null,
    },
  },
  data() {
    return {
      checkmark: require('@/assets/checkmark-base.svg'),
      currentFilter: null,
      referees: this.$options.resource([], {
        query: '',
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'User Id',
            render: (referrals) => {
              return referrals?.id;
            },
          },
          {
            name: 'full_name',
            th: 'Name',
            render: (referrals) => {
              return referrals?.full_name;
            },
          },
          {
            name: 'email',
            th: 'Email ',
            render: (referrals) => {
              return referrals?.email;
            },
          },
          {
            name: 'successful_referrals',
            th: 'Successful Referrals',
            render: (referrals) => {
              return referrals?.referrals?.filter(
                (data) => data.status == 'completed'
              ).length;
            },
          },
        ],
      }),
      form: this.$options.basicForm(['status']),
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(referee) {
      this.referees.selected = referee;
      this.$refs.refereeActionModal.open();
    },
    reloadData() {
      this.$refs.table.loadAjaxData();
    },
    setFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
  },
};
</script>
